import { Check } from '@mui/icons-material'
import {
  Avatar,
  Box,
  CardMedia,
  Container,
  Grow,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'

import { MUIWrapperContext } from '~/components/providers/MuiWrapperProvider'
import theme, { COLORS } from '~/theme'
import { type LifemapLevel } from '~/utils/types/surveys'

const colorValues = {
  1: { bgColor: COLORS.RED, textColor: theme.palette.primary.contrastText },
  2: { bgColor: COLORS.YELLOW, textColor: theme.palette.text.primary },
  3: { bgColor: COLORS.GREEN, textColor: theme.palette.primary.contrastText },
}

type QuestionValue = 1 | 2 | 3 | 9 | 0

interface StopLightQuestionCarouselProps {
  codeName: string
  interactive?: boolean
  options: LifemapLevel[]
  answeredValue: QuestionValue | null
  submitQuestion: (value: QuestionValue) => void
}

export default function StopLightQuestionCarousel({
  options,
  codeName,
  answeredValue,
  submitQuestion,
}: StopLightQuestionCarouselProps) {
  const { currentDirection } = useContext(MUIWrapperContext)

  const serializedOptions = useMemo(
    () => (currentDirection === 'rtl' ? options.reverse() : options),
    [currentDirection, options],
  )

  return (
    <Container sx={{ my: 2, position: 'relative', px: 0 }} key={codeName}>
      <Stack
        gap={2}
        flexDirection="row"
        alignItems="stretch"
        justifyContent={{ xs: 'unset', md: 'center' }}
        sx={{
          px: 2,
          pb: 1,
          overflow: 'auto',
          scrollSnapType: 'x mandatory',
        }}
      >
        {serializedOptions.map((option, idx) => (
          <Box
            key={idx}
            sx={{
              flex: 'none',
              scrollSnapAlign: 'center',
              maxWidth: { xs: 350, sm: 300 },
              width: { xs: '90%', sm: '45%' },
            }}
          >
            <QuestionCard
              option={option}
              answeredValue={answeredValue}
              submitQuestion={submitQuestion}
            />
          </Box>
        ))}
      </Stack>
    </Container>
  )
}

interface QuestionCardProps {
  option: LifemapLevel
  answeredValue: QuestionValue | null
  submitQuestion: (value: QuestionValue) => void
}

function QuestionCard({
  option,
  answeredValue,
  submitQuestion,
}: QuestionCardProps) {
  const [hasImagedLoaded, setHasImagedLoaded] = useState(false)
  const [showIconAtColor, setShowIconAtColor] = useState<QuestionValue>(0)

  const { currentDirection } = useContext(MUIWrapperContext)

  const showCheckedColor =
    answeredValue === option.value || showIconAtColor === option.value

  useEffect(() => {
    setShowIconAtColor(0)
  }, [option])

  return (
    <Stack
      spacing={2}
      onClick={() => {
        submitQuestion(option.value)
      }}
      onMouseLeave={() => {
        setShowIconAtColor(0)
      }}
      onMouseEnter={() => {
        setShowIconAtColor(option.value)
      }}
      sx={{
        p: 2,
        flexGrow: 1,
        height: '100%',
        borderRadius: 2,
        cursor: 'pointer',
        backgroundColor: colorValues[option.value].bgColor,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!hasImagedLoaded && (
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '100%', height: 'auto', aspectRatio: 1 }}
          />
        )}
        <CardMedia
          component="img"
          image={option.url}
          onLoad={() => {
            setHasImagedLoaded(true)
          }}
          style={{ display: hasImagedLoaded ? 'block' : 'none' }}
          sx={{
            borderRadius: 1,
            objectFit: 'cover',
            width: '100%',
            aspectRatio: 1,
          }}
        />

        <Grow timeout={250} in={showCheckedColor}>
          <Avatar
            sx={{
              width: 50,
              height: 50,
              bottom: -20,
              borderWidth: '2px',
              position: 'absolute',
              borderStyle: 'solid',
              bgcolor: colorValues[option.value].bgColor,
              borderColor: theme => theme.palette.primary.contrastText,
            }}
          >
            <Check />
          </Avatar>
        </Grow>
      </Box>

      <Box
        sx={{
          pt: 1,
          pb: 2,
          color: 'white',
          px: { xs: 1, sm: 2 },
          position: 'relative',
        }}
      >
        <Typography
          sx={{
            textAlign: currentDirection === 'ltr' ? 'center' : 'start',
            color: colorValues[option.value].textColor,
          }}
        >
          {option.description}
        </Typography>
      </Box>
    </Stack>
  )
}

import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { getHubs } from '~/api'
import { useAppSelector } from '~/redux/hooks'

export interface HubItem {
  label: string
  value: number
}

interface HubsFilterProps {
  value?: HubItem | null
  onChange: (newValue: HubItem | null) => void
}

export default function HubsFilter({ value, onChange }: HubsFilterProps) {
  const { t } = useTranslation()
  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })

  const hubsQuery = useQuery({
    queryKey: ['hubs-filter', user],
    queryFn: async () => await getHubs(),
    select({ data }) {
      const normalizedHubs: HubItem[] = data.data.hubsByUser.map(hub => ({
        label: hub.name,
        value: hub.id,
      }))

      return normalizedHubs
    },
  })

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      value={value}
      loading={hubsQuery.isLoading}
      loadingText={t('views.hubsFilter.loading')}
      noOptionsText={t('views.hubsFilter.noOption')}
      options={hubsQuery.data ?? []}
      onChange={(_event, newValue) => {
        onChange(newValue)
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={t('views.hubsFilter.label')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {hubsQuery.isLoading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

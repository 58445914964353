import { useSnackbar } from 'notistack'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import BottomSpacer from '~/components/BottomSpacer'
import Container from '~/components/Container'
import TitleBar from '~/components/TitleBar'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { updateDraft } from '~/redux/slices/currentDraft'
import {
  normalizeDraftForSnapshot,
  saveSnapshotDraft,
} from '~/services/lifemap-survey-services'
import {
  isTimerNum,
  remakeTimers,
  screenTimer,
  screenValidation,
} from '~/utils/survey-utils'
import { type IndicatorValue } from '~/utils/types/surveys'

import Levels from './Levels'

export default function StoplightQuestions() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const params = useParams()

  const { page } = params
  const navigate = useNavigate()
  const location = useLocation()

  const currentDraft = useAppSelector(state => {
    if (!state.currentDraft) throw new Error('No currentDraft')
    return state.currentDraft
  })
  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  const currentPage = parseInt(page ?? '')
  const question = currentSurvey.surveyStoplightQuestions[currentPage]

  const agreedTerms = location.state?.agreedTerms ?? currentDraft.agreedTerms

  const answeredValue = currentDraft.indicatorSurveyDataList.find(
    ele => question.codeName === ele.key,
  )

  function handleContinue() {
    const { state } = location

    const hasMoreQuestionsToAnswer =
      currentPage < currentSurvey.surveyStoplightQuestions.length - 1

    const hasSkippedQuestions = currentDraft.indicatorSurveyDataList.some(
      indicator => indicator.value === 0,
    )

    const normalizedDraft = normalizeDraftForSnapshot(currentDraft)
    void saveSnapshotDraft(normalizedDraft)

    if (!!state && !state.agreedTerms) {
      if (state.overviewReturn) {
        navigate('/lifemap/overview')
        return
      }

      if (!state.skippedReturn) return

      if (hasSkippedQuestions) {
        navigate('/lifemap/skipped-questions')
        return
      }

      navigate('/lifemap/overview')
      return
    }

    if (hasMoreQuestionsToAnswer) {
      const nextQuestionIndex = currentPage + 1

      navigate(`/lifemap/stoplight/${nextQuestionIndex}`)
      return
    }

    if (hasSkippedQuestions) {
      navigate('/lifemap/skipped-questions')
      return
    }

    if (!hasSkippedQuestions) {
      navigate('/lifemap/overview')
    }
  }

  function submitQuestion(chosenValue: IndicatorValue) {
    const { codeName } = question
    const surveyIndicatorsList = structuredClone(
      currentDraft.indicatorSurveyDataList,
    )

    let shouldUpdateQuestion = false
    let didQuestionValueChange = false

    //* NOTE: Here we check if the question is already in the dataList.
    //* If it is, we update it and edit the answer
    surveyIndicatorsList.forEach(indicator => {
      if (indicator.key === codeName) {
        shouldUpdateQuestion = true
        didQuestionValueChange = indicator.value !== chosenValue
        indicator.value = chosenValue
      }
    })

    if (shouldUpdateQuestion) {
      let { priorities, achievements } = structuredClone(currentDraft)
      if (didQuestionValueChange) {
        priorities = priorities.filter(
          priority => priority.indicator !== codeName,
        )
      }

      if (chosenValue !== 3) {
        achievements = achievements.filter(
          achievement => achievement.indicator !== codeName,
        )
      }

      dispatch(
        updateDraft({
          ...currentDraft,
          indicatorSurveyDataList: surveyIndicatorsList,
          priorities,
          achievements,
          stoplightSkipped: false,
        }),
      )

      handleContinue()
      return
    }

    dispatch(
      updateDraft({
        ...currentDraft,
        indicatorSurveyDataList: [
          ...currentDraft.indicatorSurveyDataList,
          { key: codeName, value: chosenValue },
        ],
        stoplightSkipped: false,
      }),
    )
    handleContinue()
  }

  function updateTimers() {
    let updatedDraft = structuredClone({ ...currentDraft, agreedTerms })

    if (isTimerNum(updatedDraft)) {
      const remakedTimers = remakeTimers(updatedDraft)
      updatedDraft = { ...updatedDraft, ...remakedTimers }
    }

    const screenTimes = screenTimer(updatedDraft, 'Question')
    dispatch(updateDraft({ ...updatedDraft, ...screenTimes }))
  }

  useEffect(() => {
    updateTimers()
  }, [currentPage])

  useEffect(() => {
    const validationResult = screenValidation(
      { ...currentDraft, agreedTerms },
      'STOPLIGHT',
      currentSurvey,
    )

    if (validationResult) {
      enqueueSnackbar(t('validation.completionRequired'), { variant: 'error' })
      navigate(validationResult)
    }

    updateTimers()
  }, [])

  return (
    <div>
      <TitleBar
        title={question?.questionText}
        extraTitleText={question?.dimension}
        progressBar
      />

      <Levels
        allowSkip
        question={question}
        onColorPick={submitQuestion}
        pickedColor={answeredValue?.value ?? null}
        allowToMarkAsDoesNotApply={question.enableDoesNotApply}
      />

      <BottomSpacer />
    </div>
  )
}
